import React, { Component } from "react"

import Layout from "components/layout"
import SEO from "components/seo"

class Deploy extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    fetch("https://api.netlify.com/build_hooks/5d5ad9807d7b180180160937", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Deploy | Tick Tock Inc." />
        <section className="ticktockinc-container-wide">
          <h1 className="has-text-centered is-size-3 has-text-weight-bold">
            {" "}
            Success. We are currently rebuilding & deploying Tick Tock Inc.'s
            website. Please make sure the Google Spreadsheet is formatted
            correctly. Otherwise, the deploy will fail. Also, please don't
            trigger this too often (maybe only a few times a day when you are
            ready to update the website for your customers).
          </h1>
        </section>
      </Layout>
    )
  }
}

export default Deploy
